.contact-page {
  font-size: 18px;
  line-height: 30px;
}
.contact-page .hero {
  background-color: #263284;
  color: #FAFAFA;
  background-image: url("/images/home-hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-page .hero h1 {
  color: #FAFAFA;
  font-weight: bold;
  font-size: 48px;
  position: relative;
  -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
}
.contact-page .section-white {
  background: none;
}
.contact-page .section-yellow {
  height: 400px;
  margin-top: -260px;
  margin-bottom: -300px;
}
.contact-page .flip {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}
.contact-page .study-one {
  margin-bottom: 8rem;
  position: relative;
  z-index: 1;
}
.contact-page .study-one .cds-card {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 992px) {
  .contact-page .study-one .cds-card {
    margin-right: -4rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
}
.contact-page .study-one .cds-image-container {
  width: auto;
}
.contact-page .study-one .cds-image-container img {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .contact-page .study-one .cds-image-container {
    margin-left: -4rem;
  }
}
.contact-page .study-two {
  margin-bottom: 8rem;
}
.contact-page .study-two .cds-card {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 992px) {
  .contact-page .study-two .cds-card {
    margin-left: -4rem;
    margin-top: 13rem;
    margin-bottom: 2rem;
  }
}
.contact-page .study-two .cds-image-container {
  width: auto;
}
.contact-page .study-two .cds-image-container img {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .contact-page .study-two .cds-image-container {
    margin-right: -4rem;
  }
}