.about-page {
  font-size: 16px;
  line-height: 28px;
}
.about-page .hero {
  background-color: #FFBF40;
  color: #FAFAFA;
  background-image: url("/images/about-hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.about-page .hero h1 {
  color: #FAFAFA;
  font-weight: bold;
  font-size: 48px;
  position: relative;
  -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
}
.about-page .section-goal {
  font-size: 20px;
  line-height: 34px;
}
.about-page .section-goal h2 {
  font-size: 36px;
  font-weight: bold;
}
.about-page .section-timeline .container {
  position: relative;
}
.about-page .section-timeline .container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80px;
  background: #FFBF40;
  border-radius: 50px;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
.about-page .section-timeline .row {
  position: relative;
  margin-bottom: 3rem;
}
.about-page .section-timeline .row .col-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.about-page .section-timeline .row::before {
  content: "";
  height: 5px;
  background-color: #263284;
  position: absolute;
  left: 30px;
  top: calc(50% - 1px);
  width: 230px;
}
.about-page .section-timeline .timeline-dot {
  position: relative;
  background-color: #263284;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  -ms-flex-item-align: center;
      align-self: center;
  margin-left: 12px;
  z-index: 1;
}
.about-page .section-timeline .timeline-circle {
  position: relative;
  background-color: #FAFAFA;
  border: 5px solid #263284;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
  -ms-flex-item-align: center;
      align-self: center;
  z-index: 1;
}
.about-page .section-timeline .timeline-title {
  position: relative;
  display: inline-block;
  padding: 1.5rem 2.5rem;
  border: 4px solid #263284;
  border-radius: 3rem;
  background-color: #fff;
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  z-index: 1;
}
.about-page .section-timeline .timeline-item {
  position: relative;
  border-radius: 10px;
  padding: 1.5rem 3rem;
  border-left: 5px solid #263284;
  background-color: #fff;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: grid;
  grid-template-columns: 110px 1fr;
}
.about-page .section-timeline .timeline-item .timeline-icon {
  font-size: 70px;
  grid-column-start: 1;
  grid-row-start: 1;
  align-self: center;
}
.about-page .section-timeline .timeline-item .timeline-main {
  grid-column-start: 2;
  grid-row-start: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.about-page .section-timeline .timeline-item .timeline-heading {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}
.about-page .section-timeline .timeline-item .timeline-link button {
  font-size: 16px;
  margin-top: 2rem;
  padding: 0;
  font-weight: bold;
  color: #263284;
  text-decoration: underline;
}
.about-page .section-timeline .timeline-item .timeline-detail {
  grid-column-start: 1;
  grid-row-start: 2;
  grid-column-end: 3;
  padding: 3rem 0 1rem;
  background-color: #fff;
}
.about-page .section-timeline .timeline-item .timeline-detail .row::before {
  height: 0;
  top: 0;
  left: 0;
}
.about-page .section-timeline .enroll-detail {
  margin-bottom: 0;
}
.about-page .section-timeline .enroll-detail .question-heading {
  font-size: 18px;
  font-style: italic;
}
.about-page .section-timeline .enroll-detail .question-text {
  margin-top: 1rem;
}
.about-page .section-timeline .enroll-detail .question-link {
  margin-top: 2rem;
}
.about-page .section-timeline .enroll-detail .question-link a {
  font-weight: bold;
}