@charset "UTF-8";
.home-page .hero {
  background-color: #263284;
  color: #FAFAFA;
}
.home-page .hero h1 {
  color: #FAFAFA;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  position: relative;
  z-index: 1;
}
.home-page .hero h1 strong {
  font-weight: 900;
  font-size: 26px;
}
@media screen and (min-width: 992px) {
  .home-page .hero h1 {
    margin-right: -8rem;
  }
}
.home-page .hero .enroll-button {
  padding: 0.75rem 3rem;
  font-size: 24px;
}
.home-page .hero .contact-link {
  color: #FAFAFA;
}
.home-page .hero .placeholder {
  aspect-ratio: 1.5/1;
  margin-bottom: -100px;
  background-color: #222d75;
}
.home-page .hero .hero-img {
  margin-bottom: -85px;
}
.home-page .hero::before {
  content: "";
  background-image: url("/images/hero-bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.home-page .cds-card {
  font-size: 18px;
  line-height: 30px;
}
.home-page .cds-card h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 2rem;
}
.home-page .cds-card ol li {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1200px) {
  .home-page .section-goal {
    margin-top: 2rem;
    margin-bottom: -10rem;
  }
}
.home-page .section-goal .goal {
  font-size: 24px;
  line-height: 38px;
  padding-top: 5rem;
  padding-left: 1rem;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .home-page .section-goal .goal {
    font-size: 26px;
    line-height: 42px;
    padding-left: 3rem;
  }
}
.home-page .section-why {
  margin-bottom: 10rem;
}
@media screen and (min-width: 992px) {
  .home-page .section-why {
    padding-top: 15rem;
    margin-bottom: 15rem;
  }
}
@media screen and (min-width: 992px) {
  .home-page .section-why .row {
    margin-bottom: -10rem;
  }
}
.home-page .section-why .cds-card {
  margin-top: 5rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  font-size: 20px;
}
@media screen and (min-width: 992px) {
  .home-page .section-why .cds-card {
    margin-right: -4rem;
  }
}
.home-page .section-why .cds-image-container {
  width: auto;
}
@media screen and (min-width: 992px) {
  .home-page .section-why .cds-image-container {
    margin-left: -4rem;
  }
}
.home-page .section-quotes .carousel-item {
  padding: 2rem 15%;
}
.home-page .section-quotes .carousel-quote-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 325px;
}
@media screen and (min-width: 768px) {
  .home-page .section-quotes .carousel-quote-container {
    min-height: 250px;
  }
}
.home-page .section-quotes .carousel-quote {
  position: relative;
  padding-left: 4rem;
  font-size: 24px;
  line-height: 36px;
  -ms-flex-item-align: center;
      align-self: center;
}
.home-page .section-quotes .carousel-quote::before {
  content: "“";
  position: absolute;
  left: 0;
  top: 1rem;
  font-size: 120px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  color: #FFBF40;
  opacity: 0.3;
}
.home-page .section-quotes .quote-long {
  font-size: 18px;
  line-height: 28px;
}
.home-page .section-quotes .carousel-control-prev-icon {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23263284' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.home-page .section-quotes .carousel-control-next-icon {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23263284' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}