.team-page .hero {
  background-color: #ED3340;
  color: #FAFAFA;
  background-image: url("/images/team-hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.team-page .hero h1 {
  color: #FAFAFA;
  font-weight: bold;
  font-size: 48px;
  position: relative;
  -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
}
.team-page .section-filter {
  margin-top: -120px;
}
.team-page .section-filter a {
  text-decoration: none;
}
.team-page .section-filter a:hover {
  text-decoration: underline;
}
.team-page .hero-card {
  text-align: center;
  padding: 2rem 0.5rem;
  font-weight: bold;
  line-height: 20px;
}
.team-page .group-title {
  position: relative;
}
.team-page .group-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #263284;
}
.team-page .member-item {
  margin-bottom: 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.team-page .member-item .member-photo {
  border-radius: 10px;
  padding: 1rem;
  border-top: 5px solid #263284;
  background-color: #FAFAFA;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .team-page .member-item .member-photo {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.team-page .member-item .member-photo .photo-wrapper {
  border-radius: 10px;
  aspect-ratio: 1/1.2;
  overflow: hidden;
  min-width: 115px;
}
.team-page .member-item .member-photo .photo-wrapper img {
  width: 100%;
}
.team-page .member-item .member-photo .wrapper-short {
  aspect-ratio: 1/1;
}
.team-page .member-item .member-main {
  padding: 2rem 1rem;
}
.team-page .member-item .member-main .member-name {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0.75rem;
  text-align: center;
}
.team-page .member-item .member-main .member-title {
  margin-bottom: 1rem;
  text-align: center;
}
.team-page .member-item .member-main .member-bio {
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 28px;
}
.team-page .member-item .member-main .member-bio ul {
  padding-left: 2rem;
}
.team-page .member-item .member-main .member-research a {
  font-weight: bold;
  font-size: 18px;
}
@media screen and (min-width: 768px) {
  .team-page .member-item .member-main {
    padding-left: 3rem;
    padding-right: 1rem;
  }
  .team-page .member-item .member-main .member-name {
    text-align: left;
  }
  .team-page .member-item .member-main .member-title {
    text-align: left;
  }
}
.team-page .member-item-right .member-main {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (min-width: 768px) {
  .team-page .member-item-right .member-photo {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .team-page .member-item-right .member-main {
    padding-left: 1rem;
    padding-right: 4rem;
  }
}