.book-page .hero {
  background-color: #FFBF40;
  color: #FAFAFA;
  background-image: url("/images/about-hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.book-page .hero h1 {
  color: #FAFAFA;
  font-weight: bold;
  font-size: 48px;
  position: relative;
  -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
}
.book-page .carousel-control-prev-icon {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23263284' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.book-page .carousel-control-next-icon {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23263284' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.book-page .download-link {
  font-size: 18px;
  font-weight: bold;
}