.faq-page .hero {
  background-color: #FFBF40;
  color: #FAFAFA;
  background-image: url("/images/about-hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.faq-page .hero h1 {
  color: #FAFAFA;
  font-weight: bold;
  font-size: 48px;
  position: relative;
  -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
}
.faq-page .section-filter {
  margin-top: -120px;
}
.faq-page .section-filter a {
  text-decoration: none;
}
.faq-page .section-filter a:hover {
  text-decoration: underline;
}
.faq-page .hero-card {
  text-align: center;
  padding: 2rem 0;
  font-weight: bold;
}
.faq-page .faq-title h2 {
  font-weight: bold;
  font-size: 38px;
}
.faq-page .faq-item button.btn {
  position: relative;
  font-weight: bold;
  font-size: 22px;
  color: #263284;
  border: 1px solid #c1c1c1;
  border-radius: 0;
  padding: 1.5rem 130px 1.5rem 2rem;
  display: block;
  width: 100%;
  text-align: left;
  background-color: transparent;
}
.faq-page .faq-item button.btn::before {
  content: "";
  height: 100%;
  width: 8px;
  background-color: #ED3340;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.faq-page .faq-item button.btn::after {
  position: absolute;
  right: 50px;
  bottom: calc(50% - 1rem);
  width: 2rem;
  height: 2rem;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23707070'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 2rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.faq-page .faq-item button.btn:not(.collapsed)::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.faq-page .faq-item .faq-detail {
  margin-left: 5%;
  width: 95%;
  border: 1px solid #c1c1c1;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}
.faq-page .faq-item .faq-detail .faq-detail-container {
  padding: 2.5rem;
  font-size: 20px;
  line-height: 34px;
  background-color: rgba(255, 191, 64, 0.0666666667);
}
.faq-page .faq-item .faq-detail li::marker {
  color: #263284;
}